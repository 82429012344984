import React, { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import FilterCode from "app/components/diy_composer/filter_code"
import Header from "../header"
import LearnMoreButton from "shared/components/learn_more_button"
import chevronLeftBlack from "images/layout/chevron-left-black.svg"
import MobileGateModal from "app/components/diy_composer/filter_code/mobile_gate_modal.tsx"

import ConnectionStoredFieldsForm from "./connection_stored_fields_form"
import Footer from "./footer"
import { cancellationError } from "app/components/diy_composer/utils/error_map"
import { storedFieldOwner } from "shared/lib/format_stored_field"
import classNames from "classnames"

import { filterCodeQuery, aiFieldSuggestion } from "./api"
import isEmpty from "lodash/isEmpty"

import { clearState } from "shared/localstorage_helper"

import appletOrConnection from "shared/lib/use_applet_or_connection"
import { capitalize } from "humanize-plus"

import {
  attachNewBadgeForMultiServiceAccountButton,
  removeMultiServiceAccountNewBadge,
} from "shared/scripts/new_badge_button"

import UpgradePlanModal from "app/components/upgrade_plan_modal"

import FieldContext from "shared/contexts/field_context"

export default class ConnectionConfig extends Component {
  configType = appletOrConnection(this.props.connection)

  state = {
    enabled: true, // hack to unblock ComEd until we can resolve the form validation properly
    fieldSuggestions: {},
    loadingSuggestions: false,
    isSaving: false,
    pushEnabled: !!this.props.connection.push_enabled,
    appletFilterData: {},
    showFilterCode: false,
    name: this.props.connection.name,
    didChangeSetting: false,
    upgradeToPro: {
      showModal: false,
      step: "queries",
    },
    currentTier: this.props.user.tier,
    permissions: this.props.user.permissions,
    userAllowMultipleLiveChannels: this.props.user.userAllowMultipleLiveChannels,
    multipleLiveChannelsNewBadgeVisibility:
      this.props.user.permissions.multi_service_account.permitted === true &&
      attachNewBadgeForMultiServiceAccountButton(),
    showingCheckoutModal: false,
    checkoutPlanTarget: "intermediate_pro",
    showFilterCodeMobileGate: false,
  }

  appletFeatureFlags = {
    pro_features: this.props.connection.pro_features,
    intermediate_pro_features: this.props.connection.intermediate_pro_features,
  }

  componentDidMount() {
    const isOwner = this.props.connection.author === this.props.user.login

    filterCodeQuery(this.props.connection.id, isOwner)
      .then(data => {
        this.setState({
          appletFilterData: data.applet || {},
        })
      })
      .catch(err => console.warn(err))

    window.addEventListener("beforeunload", this.handleBeforeUnload, {
      once: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload, {
      once: true,
    })
  }

  handleBeforeUnload = event => {
    if (this.state.didChangeSetting) {
      event.returnValue = "You have unsaved changes. Do you want to leave this page and discard your changes?"
    }
  }

  mobileFilterCodeGate = async () => {
    if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 1000) {
      this.setState({ showFilterCodeMobileGate: true })
    } else {
      this.setState({ showFilterCode: !this.state.showFilterCode })
    }
  }

  toggleShowFilterCode = () => this.setState({ showFilterCode: !this.state.showFilterCode })

  onSaveFilterCode = filterCode =>
    this.setState({
      appletFilterData: {
        ...this.state.appletFilterData,
        filterCode,
      },
      showFilterCode: false,
      didChangeSetting: true,
    })

  formatAppletStepToStep(type) {
    return appletStep => {
      const fields = appletStep.step.fields
        .map(stepField => {
          const appletStepField = appletStep.fields.find(f => f.name === stepField.name)
          const storedField = this.props.formFields.storedFields[""].find(
            sf => sf.name === stepField.name && sf.owner === storedFieldOwner(type, appletStep.step.full_module_name)
          )
          return {
            ...stepField,
            hidden: appletStepField?.hidden,
            value: storedField?.value || storedField?.default_value,
          }
        })
        .reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {})

      return {
        ...appletStep.step,
        fields,
        id: appletStep.step.id,
        full_module_name: appletStep.step.full_module_name,
      }
    }
  }

  onSaveClicked = () => {
    const aiAppletId = localStorage.getItem("ai_applet_being_created")
    if (aiAppletId === this.props.connection.id) {
      window.App.Utils?.logCustomDatadogAction?.("ai_applet_enabled", { applet_id: aiAppletId })
      window.App.Utils?.logCustomDatadogAction?.("diy_or_ai_applet_created", { applet_id: aiAppletId })
      localStorage.removeItem("ai_applet_being_created")
    }
    this.setState({ didChangeSetting: false, isSaving: true })
    const { name, pushEnabled } = this.state
    this.storedFieldsForm.onSubmit(name, pushEnabled)
  }

  onDoneSaving = enabled => {
    this.setState({ isSaving: false, enabled })
  }

  onSaveName = name => {
    this.setState({ name, didChangeSetting: true })
  }

  onFieldChanged = automated => {
    if (!automated) {
      this.setState({ didChangeSetting: true })
    }
  }

  clearLocalStorage = () => {
    const localStorageId = `connectionId-${this.props.connection.id}-${this.props.sessionId}`
    clearState(localStorageId)
  }

  onClickBackButton = e => {
    e.preventDefault()

    this.clearLocalStorage()
    window.history.back()
  }

  onFormEnabled = () => {
    this.setState({ enabled: true })
  }

  onFormDisabled = () => {
    // this.setState({ enabled: false }) // hack to unblock ComEd until we can resolve the form validation properly
  }

  hideIngredientNamespaces = () => {
    const { storedFields } = this.props.formFields
    const namespacedIngredientRegex = /{{[^{}]+\\\.[^{}]+}}/
    return Object.values(storedFields)
      .flat()
      .filter(sf => sf.field_type === "text")
      .map(sf => [sf.value, sf.default_value])
      .flat()
      .some(value => value && value.match(namespacedIngredientRegex))
  }

  proFeatureGate = async permissionName => {
    if (this.state.permissions[permissionName]?.permitted) {
      return Promise.resolve()
    } else {
      this.setState({
        upgradeToPro: {
          showModal: true,
          step: permissionName,
        },
      })
      return Promise.reject(cancellationError)
    }
  }

  updatePermissionsAfterUpgrade = tier => {
    const isPro = tier === "pro"

    this.setState({
      multipleLiveChannelsNewBadgeVisibility: true,
      currentTier: tier,
      permissions: {
        ...this.state.permissions,
        filter_code: {
          ...this.state.permissions.filter_code,
          permitted: isPro,
        },
        multi_action: {
          ...this.state.permissions.multi_action,
          permitted: true,
        },
        queries: {
          ...this.state.permissions.queries,
          permitted: isPro,
        },
        multi_service_account: {
          ...this.state.permissions.multi_service_account,
          permitted: isPro,
        },
        ai_field_generation: {
          ...this.state.permissions.ai_field_generation,
          permitted: isPro,
        },
      },
    })
  }

  async fetchAISuggestions() {
    this.setState({ loadingSuggestions: true })
    const actionFields = this.props.formFields.storedFields["action"].filter(field => {
      return field.field_type === "text"
    })

    let suggestions = {}
    let suggestionFailure = false

    const promises = actionFields.map(async field => {
      const permissionId = field.owner.match(/\/actions\/[^.]+\.[^/]+/)[0]
      const suggestion = await aiFieldSuggestion({
        appletId: this.props.connection.id,
        permissionId,
        fieldName: field.name,
      }).then(({ ai_field_suggestion }) => ai_field_suggestion)

      if (suggestion.errors.length) {
        suggestionFailure = true
      } else {
        suggestions[`stored_fields[${field.owner}][${field.name}]`] = suggestion.value
      }
    })

    await Promise.all(promises)

    if (suggestionFailure) {
      window.appendFlash("There was an error fetching some of your suggestions.")
    }

    this.setState({ fieldSuggestions: suggestions, loadingSuggestions: false })
  }

  render() {
    const { showFilterCode, appletFilterData } = this.state
    const isStaticConfig = this.props.connection.config_type === "static"

    return (
      <>
        <div className="config-header">
          <button
            type="submit"
            className="button-tertiary button-outlined button-with-icon only-icon-on-mobile connection-edit-back-button"
            aria-label="Back button"
            onClick={showFilterCode ? this.toggleShowFilterCode : this.onClickBackButton}
          >
            <span className="button-icon">{parse(chevronLeftBlack)}</span>
            <span className="hide-on-xs">Back</span>
          </button>
          <h2 className="settings-heading">
            {showFilterCode ? "Edit filter code" : `${capitalize(this.configType)} settings`}
          </h2>
          {isStaticConfig && !showFilterCode && <LearnMoreButton {...this.props.header.learnMoreButton} />}
        </div>
        <div
          className={classNames("connection-config-editor", {
            "filter-code": showFilterCode,
          })}
        >
          {showFilterCode ? (
            <FilterCode
              filterCode={appletFilterData.filterCode}
              contextTemplate={this.props.filterCodeTemplate}
              selectedTrigger={this.formatAppletStepToStep("trigger")(appletFilterData.applet_trigger)}
              selectedQueries={appletFilterData.applet_queries.map(query =>
                this.formatAppletStepToStep("query")(query)
              )}
              selectedActions={appletFilterData.applet_actions.map(action =>
                this.formatAppletStepToStep("action")(action)
              )}
              updateFilterCode={this.onSaveFilterCode}
            />
          ) : (
            <>
              <Header
                connection={{ ...this.props.connection, brand_color: "#fff" }}
                mainService={this.props.mainService}
                authorInfo={this.props.authorInfo}
                icons={this.props.icons}
                programmable={this.props.programmable}
                includeEditableTitle={this.props.includeEditableTitle}
                onSaveName={this.onSaveName}
                onCompleteWithAI={this.fetchAISuggestions.bind(this)}
                loadingSuggestions={this.state.loadingSuggestions}
                showCompleteWithAI={this.state.permissions.ai_field_generation.permitted === true}
              />
              {this.props.connection.description && (
                <div className="connection-description">
                  <h2 className="txt-body-2">{this.props.connection.description}</h2>
                </div>
              )}
              {!isEmpty(this.props.formFields.storedFields) && (
                <>
                  <hr />

                  <div className="connection-fields-form">
                    <FieldContext.Provider value={this.state.fieldSuggestions}>
                      <ConnectionStoredFieldsForm
                        ref={r => (this.storedFieldsForm = r)}
                        onFormEnabled={this.onFormEnabled}
                        onFormDisabled={this.onFormDisabled}
                        applet={this.props.connection}
                        submitUrl={this.props.urls.updateURL}
                        connectionURL={this.props.urls.connectionURL}
                        userAllowMultipleLiveChannels={this.state.permissions.multi_service_account.permitted === true}
                        proFeatureGate={this.proFeatureGate}
                        connectionFinishedUrl={this.props.urls.connectionFinishedUrl}
                        clearLocalStorage={this.clearLocalStorage}
                        programmable={this.props.programmable}
                        hideIngredientNamespaces={this.hideIngredientNamespaces()}
                        filterCode={appletFilterData.filterCode}
                        showFilterCta={!!appletFilterData.filterCode}
                        toggleShowFilterCode={this.mobileFilterCodeGate}
                        onFieldChanged={this.onFieldChanged}
                        onDoneSaving={this.onDoneSaving}
                        multipleLiveChannelsNewBadgeVisibility={this.state.multipleLiveChannelsNewBadgeVisibility}
                        hideMultipleLiveChannelsNewBadge={() => {
                          this.setState({
                            multipleLiveChannelsNewBadgeVisibility: false,
                          })
                          removeMultiServiceAccountNewBadge()
                        }}
                        {...this.props.formFields}
                      />
                    </FieldContext.Provider>
                  </div>
                </>
              )}
              {/*
                Conditionally load UpgradePlanModal (it's only relevant for
                Applets aka static config, not Connections aka dynamic config)
              */}
              {isStaticConfig && (
                <>
                  <UpgradePlanModal
                    appletFeatureFlags={this.appletFeatureFlags}
                    onClose={() =>
                      this.setState({
                        upgradeToPro: {
                          showModal: false,
                          step: "queries",
                        },
                      })
                    }
                    onUpgrade={checkoutModalTarget => this.updatePermissionsAfterUpgrade(checkoutModalTarget)}
                    proPlusProductId={this.props.proPlusProductId}
                    step={this.state.upgradeToPro.step}
                    tierRequirement={this.state.upgradeToPro.stepTier}
                    trackLocationType="connection_config"
                    urls={{
                      newProSubscriptionUrl: this.props.urls.newProSubscriptionUrl,
                      newProPlusSubscriptionUrl: this.props.urls.newProPlusSubscriptionUrl,
                      modalSubscriptionUrl: this.props.urls.modalSubscriptionUrl,
                    }}
                    user={{
                      eligibleForTrial: this.props.user.eligibleForTrial,
                      eligibleTrialPeriod: this.props.user.eligibleTrialPeriod,
                      isTrialing: this.props.user.isTrialing,
                      subscriptionPaymentType: this.props.user.subscriptionPaymentType,
                      tier: this.state.currentTier,
                    }}
                    visible={this.state.upgradeToPro.showModal}
                  />
                  <MobileGateModal
                    device={this.props.mobile_device}
                    onClose={() =>
                      this.setState({
                        showFilterCodeMobileGate: !this.state.showFilterCodeMobileGate,
                      })
                    }
                    visible={this.state.showFilterCodeMobileGate}
                  />
                </>
              )}
              <Footer
                enabled={this.state.enabled}
                isSaving={this.state.isSaving}
                includeSubmit={!isEmpty(this.props.formFields.storedFields)}
                onSaveClicked={this.onSaveClicked}
              />
              <div className="breath-space" />
              <div className="connection-id">
                ID <span>{this.props.connection.id}</span>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
}

ConnectionConfig.propTypes = {
  includeEditableTitle: PropTypes.bool,
  formFields: PropTypes.shape({
    storedFields: PropTypes.objectOf(PropTypes.array),
    configuration: PropTypes.objectOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
    disabled_slugs: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.object,
    user: PropTypes.object,
    ingredientsMetadata: PropTypes.object,
    includeDescriptions: PropTypes.bool,
    includeHeader: PropTypes.bool,
    includeFooter: PropTypes.bool,
    useInlineErrors: PropTypes.bool,
    onFormEnabled: PropTypes.func,
    onFormDisabled: PropTypes.func,
    showSuccessMessage: PropTypes.bool,
    useIngredientsForAppletOwners: PropTypes.bool,
  }),
  urls: PropTypes.shape({
    activityURL: PropTypes.string,
    myAppletURL: PropTypes.string,
    connectionURL: PropTypes.string,
    updateURL: PropTypes.string,
    connectionFinishedUrl: PropTypes.string,
    newProSubscriptionUrl: PropTypes.string,
    newProPlusSubscriptionUrl: PropTypes.string,
    modalSubscriptionUrl: PropTypes.string,
  }),
  header: PropTypes.shape({
    backButton: PropTypes.object,
    learnMoreButton: PropTypes.object,
  }),
  filterCodeTemplate: PropTypes.string,
  sessionId: PropTypes.string.isRequired,
  connection: PropTypes.object,
  programmable: PropTypes.bool,
  icons: PropTypes.object,
  mainService: PropTypes.object.isRequired,
  authorInfo: PropTypes.object.isRequired,
  proPlusProductId: PropTypes.string,
  mobile_device: PropTypes.oneOf(["ios", "android", "none"]).isRequired,
  user: PropTypes.shape({
    login: PropTypes.string.isRequired,
    tier: PropTypes.string,
    appletQuota: PropTypes.shape({
      remaining: PropTypes.number,
      total: PropTypes.number,
    }),
    eligibleForTrial: PropTypes.bool,
    eligibleTrialPeriod: PropTypes.number,
    subscriptionPaymentType: PropTypes.string,
    isTrialing: PropTypes.bool,
    userAllowMultipleLiveChannels: PropTypes.bool,
    permissions: PropTypes.objectOf(
      PropTypes.shape({
        permitted: PropTypes.bool.isRequired,
        minimumTier: PropTypes.string,
      })
    ),
  }),
}
