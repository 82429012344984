const modalHandler = (modalId: string, modalOpenSelector: string) => {
  const modal = document.getElementById(modalId)
  const open = document.querySelector(modalOpenSelector)
  const background = modal?.querySelector(".modal-background")
  const close = modal?.querySelector(".modal-close")
  const modalContent = modal?.querySelector(".modal-content")

  if (!modal || !open || !close || !background || !modalContent) return

  // Attach listener to modal-content once so clicks inside don't trigger the background.
  modalContent.addEventListener("click", (event) => {
    event.stopPropagation()
  })

  const toggleOpen = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()

    const isClosed = modal.classList.contains("hidden");
    if (isClosed) {
      open.removeEventListener("click", toggleOpen)
      modal.classList.remove("hidden")

      close.addEventListener("click", toggleOpen)
      background.addEventListener("click", toggleOpen)
    } else {
      close.removeEventListener("click", toggleOpen)
      background.removeEventListener("click", toggleOpen)
      modal.classList.add("hidden")

      open.addEventListener("click", toggleOpen)
    }
  }

  open.addEventListener("click", toggleOpen)
}

export default modalHandler
