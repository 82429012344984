const shareButton = (shareUrl: string, title: string) => {
  const shareModal = document.querySelector(".share-modal")
  const shareURLInputs = Array.from(shareModal?.querySelectorAll(".share-url-input") || [])
  const copyButtons = Array.from(shareModal?.querySelectorAll("button.copy") || [])
  const twitterButton = shareModal?.querySelector("a.twitter")
  const facebookButton = shareModal?.querySelector("a.facebook")

  if (!shareURLInputs.length || !copyButtons.length || !twitterButton || !facebookButton) {
    console.error("Missing elements for shareButton")
    return
  }

  const onInputClick = (e: Event) => {
    const shareURLInput = shareURLInputs.find(
      input => window.getComputedStyle(input).display !== "none"
    ) as HTMLInputElement
    if (!shareURLInput) return

    shareURLInput.select()
  }

  const onCopy = (e: Event) => {
    navigator.clipboard.writeText(shareUrl)
      .catch(error => console.error("Clipboard write failed:", error))

    const copyButton = copyButtons.find(cb => window.getComputedStyle(cb).display !== "none")
    const shareURLInput = shareURLInputs.find(
      input => window.getComputedStyle(input).display !== "none"
    ) as HTMLInputElement
    if (!copyButton || !shareURLInput) return

    shareURLInput.select()

    copyButton.textContent = "Copied"
    copyButton.setAttribute("disabled", "true")
    setTimeout(() => {
      copyButton.removeAttribute("disabled")
      copyButton.textContent = "Copy"
    }, 1000)
  }

  const onFacebookClick = (e: Event) => {
    // When FB is not available, use the direct anchor link
    if (window.FB) {
      e.preventDefault()
      window.FB.ui(
        {
          method: "share",
          href: shareUrl,
        },
        () => {}
      )
    }
  }

  const onTwitterClick = (e: Event) => {
    e.preventDefault()
    window.open(
      `https://twitter.com/intent/tweet?text=${title}&url=${shareUrl}`,
      "Join IFTTT",
      "scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420"
    )
  }

  for (let input of shareURLInputs) { input.addEventListener("click", onInputClick) }
  for (let button of copyButtons) { button.addEventListener("click", onCopy) }
  facebookButton.addEventListener("click", onFacebookClick)
  twitterButton.addEventListener("click", onTwitterClick)
}

export default shareButton
